<template>
  <div class="form">
    <div class="form-group">
      <label>
        {{ $t("pages.applications.advanced.form.delete") }}
      </label>
      <p class="warning">
        {{ $t("pages.applications.advanced.form.warning") }}
      </p>
      <button
        type="button"
        class="btn btn-danger field-submit"
        v-on:click="deleteHmacAuthApplication"
      >
        {{
          isDeletingApplication
            ? $t("pages.delete_hmac_auth_application.form.button.deleting")
            : $t("pages.delete_hmac_auth_application.form.button.delete")
        }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { APPLICATION_DELETE_HMAC_AUTH } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";
import { emit } from "@/utils/event";
import { event, evenType } from "@/events/mixpanel/enums/applications";
import { mixpanelInstance } from "@/utils/mixpanel";

interface Data {
  isDeletingApplication: boolean;
}

export default defineComponent({
  data(): Data {
    return {
      isDeletingApplication: false,
    };
  },
  methods: {
    async deleteHmacAuthApplication() {
      if (this.isDeletingApplication) {
        return;
      }

      this.isDeletingApplication = true;

      const metadata = {
        CompanySSOID: this.$store.state.application.hmacAuthApplication?.companyId
      };
      const dataEvent = {
        Remarks: "Success",
        Reason: "Success delete application",
      }

      try {
        await this.$store.dispatch(
          APPLICATION_DELETE_HMAC_AUTH,
          this.$store.state.application.hmacAuthApplication?.id
        );
        mixpanelInstance.setMetadata(metadata);
        emit(event.DeleteApplication, dataEvent);
        this.handleSuccess(`${dataEvent.Reason} "${this.$store.state.application.hmacAuthApplication?.name}"`);
      } catch (err) {
        dataEvent.Remarks = "Failed";
        dataEvent.Reason = (err as any).response
        ? (err as any).response.data.message
        : "Something went wrong. Try again";
        mixpanelInstance.setMetadata(metadata);
        emit(event.DeleteApplication, dataEvent);
        this.handleError(err);
      } finally {
        this.$router.push("/dashboard/applications");
        this.isDeletingApplication = false;
      }
    },
    handleError(err: Error | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: err.message,
        type: ToastType.ERROR,
      });
    },
    handleSuccess(msg: string | any) {
      this.$store.dispatch(SHOW_TOAST, {
        message: msg,
        type: ToastType.SUCCESS,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.form-group {
  label {
    margin-bottom: 4px;
  }

  .form-control {
    width: 280px;
  }

  .warning {
    color: $color-gray-slate;
    margin-bottom: 20px;
  }
}
</style>
